<template>
    <div id="equipmentDetail" :class="equipmentDetail" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <div class="header" v-if="kind==1">
        <!-- 左上角头部开关    -->
            <div class="header-title">
                 <a-icon type="desktop"  class="header-icon"/>
                <span>{{baseInfo.name}}</span>  
            </div>
            <div>
                <!-- 返回上一级箭头   -->
                <a-icon
                    type="close" 
                    :style="{ fontSize: '20px', color: '#7682CE' }"
                   @click="goBack"
                />
            </div>
        </div>
        <div :class="mainCls" >
            <div class="box" >
                
                <a-descriptions :title="$t('distribution.a31')">
                    <a-descriptions-item :label="$t('distribution.a30')">
                         {{baseInfo.monitorNo}}
                    </a-descriptions-item>
                    <a-descriptions-item :label="$t('distribution.a29')" :span="2">
                        {{baseInfo.name}}
                    </a-descriptions-item>
                    
                    <a-descriptions-item :label="$t('distribution.a28')">
                        <a-cascader 
                            v-model="baseInfo.grs" 
                            :field-names="{ label: 'text', value: 'no', children: 'children' }" style="width:100%;" :options="mpointGrs" 
                            change-on-select disabled/>
                    </a-descriptions-item>

                    <a-descriptions-item :label="$t('distribution.a27')" >
                        <a-select v-model="baseInfo.iconNo"  style="width: 100%;" disabled>
                            <a-select-option
                                v-for="(item, index) in miconList"
                                :key="index"
                                :value="item.no"
                                >
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                    </a-descriptions-item>

                    <a-descriptions-item :label="$t('distribution.a26')">
                        <a-select v-model="baseInfo.displayDevice"  style="width: 100%;" disabled>
                            <a-select-option
                                v-for="(item, index) in displayTypes"
                                :key="index"
                                :value="item.no"
                                >
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                    </a-descriptions-item>

                    <a-descriptions-item :label="$t('distribution.a25')" :span="2" v-if="baseInfo.displayDevice===1">
                        {{baseInfo.deviceKey}}
                    </a-descriptions-item>

                   <a-descriptions-item :label="$t('distribution.a3')" v-if="baseInfo.displayDevice===1" >
                         <a-tree-select disabled
                            v-model="userId"
                            style="width: 100%;"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="userTreeData"
                            placeholder="Please select"
                            tree-default-expand-all
                            :replace-fields="{children:'children', title:'name', key:'key', value: 'no' }"
                        >
                        </a-tree-select>
                    </a-descriptions-item>

                    <a-descriptions-item :label="$t('distribution.a13')" :span="2" >
                        <div v-html="displayHref"></div>
                    </a-descriptions-item>

                    <a-descriptions-item label="" >
                       <a-button type="primary" icon="copy" v-clipboard:copy="baseInfo.displayUrl" v-clipboard:success="onCopy">  {{$t('distribution.a24')}}</a-button>
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <div class="box" v-if="baseInfo.displayDevice>1">
                <div class="ant-descriptions-title">{{$t('distribution.a23')}}</div>
                <a-form-model ref="configForm" layout="horizontal"  :model="configForm"  :rules="configRules" :label-col="labelCol3" :wrapper-col="wrapperCol3" >
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item :label="$t('distribution.a21')" ref="deviceIp" prop="deviceIp">
                                <a-input  v-model="configForm.deviceIp" @blur=" () => { $refs.deviceIp.onFieldBlur(); } "  :placeholder="$t('distribution.a22')" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">

                            <a-form-model-item :label="$t('distribution.a19')" ref="devicePort" prop="devicePort">
                                <a-input   v-model="configForm.devicePort" @blur=" () => { $refs.devicePort.onFieldBlur(); } "  :placeholder="$t('distribution.a20')"  />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item :label="$t('distribution.a17')" ref="serverIp" prop="serverIp">
                                <a-input v-model="configForm.serverIp" @blur=" () => { $refs.serverIp.onFieldBlur(); } " :placeholder="$t('distribution.a18')"  />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item :label="$t('distribution.a15')" ref="serverPort" prop="serverPort">
                                <a-input   v-model="configForm.serverPort" @blur=" () => { $refs.serverPort.onFieldBlur(); } "  :placeholder="$t('distribution.a16')"  />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item :label="$t('distribution.a13')" ref="displayUrl" prop="displayUrl" >
                                 <a-input style="width:100%;"   v-model="configForm.displayUrl" @blur=" () => { $refs.displayUrl.onFieldBlur(); } "  :placeholder="$t('distribution.a14')"  />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item :label="$t('distribution.a11')" ref="deviceKey" prop="deviceKey">
                                <a-input   v-model="configForm.deviceKey" @blur=" () => { $refs.deviceKey.onFieldBlur(); } "  :placeholder="$t('distribution.a12')"  />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    
                    <a-button type="primary" style="width: 120px" @click="configEquipment"> {{$t('energy.ok')}}  </a-button>
                </a-form-model>
                    
            </div>
            <div class="box" v-if="baseInfo.displayDevice==1">
                <img v-if="themeImage==='board2'" class="theme-image" src="../../../public/images/board2/theme.png" />
                <img v-else-if="themeImage==='board'" class="theme-image" src="../../../public/images/board/theme.png" />
                <div class="ant-descriptions-title">{{$t('distribution.a10')}}</div>
                <a-form-model ref="boardForm"  :model="boardForm" 
                 :label-col="labelCol" 
                 :wrapper-col="wrapperCol2"  >
                        
                    <a-form-model-item :label="$t('distribution.a8')" ref="theme" prop="theme" :rules="configRules.theme"      >
                        <a-select v-model="boardForm.theme" :placeholder="$t('distribution.a9')" style="width:300px;" @change="themeChange">
                            <a-select-option
                                v-for="(item, index) in themeList"
                                :key="index"
                                :value="item.no" 
                                >
                                {{ item.text}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item :label="$t('distribution.a6')" ref="title" prop="title" :rules="configRules.title">
                        <a-input style="width:300px;"  v-model="boardForm.title" @blur=" () => { $refs.title.onFieldBlur(); } "  :placeholder="$t('distribution.a7')" />
                    </a-form-model-item>
                
                    <a-form-model-item :label="$t('distribution.a4')" ref="flushTime" prop="flushTime" :rules="configRules.flushTime">
                        <a-input style="width: 200px;"   v-model="boardForm.flushTime"  :placeholder="$t('distribution.a5')"  />
                    </a-form-model-item>
                    <a-form-model-item :label="$t('distribution.a3')" ref="userId" prop="userId" :rules="configRules.userId"      >
                         <a-tree-select
                            v-model="boardForm.userId"
                            style="width:300px;"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="userTreeData"
                            :placeholder="$t('distribution.a2')"
                            tree-default-expand-all
                            :replace-fields="{children:'children', title:'name', key:'key', value: 'no' }"
                            @change="userChange"
                        >
                        </a-tree-select>
                    </a-form-model-item>
                    <a-form-model-item :label="$t('distribution.a1')" ref="model" prop="model" :rules="configRules.model">
                        <a-select v-model="boardForm.model" :placeholder="$t('distribution.a41')" style="width:300px;" mode="multiple">
                            <a-select-option :disabled="item.contractStatus!==3"
                                v-for="(item, index) in siteServiceList"
                                :key="index"
                                :value="item.siteId + '@@' + item.siteServiceTypeID + '@@' + item.contractStatus" 
                                :dropdown-style="{ maxHeight: '100px', overflow: 'auto'}"
                                >
                                {{ item.name + '--' + item.siteServiceTypeName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item  v-bind="tailFormItemLayout" >
                        <a-button type="primary"  @click="saveBoardCfg" icon="save" >{{$t('energy.ok')}}  </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </div>
</template>
<script>
import {getCommonEquipment,initBoardAccessKey,getUserSiteServiceList,saveCommonEquipment} from "../../api/energy";
export default {
    props: {
        id: {
            type: Number,
            default:0,
        },
        equipmentSiteId: {
            type: Number,
            default:0,
        },
        // equipment:{
        //     type:Object,
        //     default:{}
        // },
        // iconList:{
        //     type:Array,
        //     default:[],
        // },
        // pointGrs:{
        //     type:Array,
        //     default:[],
        // }
    },
    data(){
        let normalName = (rule, value, callback) => {
            let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
    
            if(regEn.test(value)) {
                callback(new Error(this.$t('energy.enedata260')));
            } else {
                callback();
            }
        };
        let checkPending;
        let checkNumber = (rule, value, callback) => {
            clearTimeout(checkPending);
            if (!value) {
                return callback(new Error('Please input the flush time'));
            }
            checkPending = setTimeout(() => {
                console.log(value);
                let val = new Number(value);
                console.log(val);
                if (isNaN(val)) {
                    callback(new Error('Please input digits'));
                } else {
                    if (value < 1) {
                        callback(new Error('flush time must be greater than 1'));
                    }else if (value >1440) {
                        callback(new Error('flush time must be laster than 1440'));
                    } else {
                        callback();
                    }
                }
            }, 200);
        };
        return {
            loading:false,
            baseInfo:{
                id:0				,
                name:''             ,
                monitorId:0         ,
                monitorNo:0         ,
                grs:[]				,
                iconNo:0            ,
                displayDevice:0     ,
                description:''      ,
                siteId:0            ,
                smartGatewayId:0    ,
                parentId:0          ,
                deviceId:0          ,
                displayUrl:''       ,
                deviceKey:''        ,
            },
            configForm:{
                id:0,
                monitorId:0,
                deviceIp :"",
                devicePort:"",
                deviceKey:"",
                serverIp:"",
                serverPort:"",
                displayUrl:"",
                memo:""
            },
            themeImage:undefined,
            boardForm:{
                userId:'',
                title:'',
                model:[],
                flushTime:1,
                theme:'board',
            },
            configRules:{
                iconNo: [{ required: true, message: this.$t('energy.enedata468'), trigger: 'change' }],
                title:[
                    { required: true, message: this.$t('distribution.a40'), trigger: 'blur' },//请输入标题
                    { validator:normalName, trigger: 'blur' }
                ],
                flushTime:[
                    { required: true, message: this.$t('distribution.a41'), trigger: 'blur' },//请输入数据刷新时间
                    { validator:checkNumber, trigger: 'blur' }
                ],
                userId:[{ required: true, message: this.$t('distribution.a2'), trigger: 'change' }],//请选择用户
                theme:[{ required: true, message: this.$t('distribution.a42'), trigger: 'change' }],//请选择主题
                model:[{
                    type: 'array',
                    required: true,
                    message: this.$t('distribution.a42'),//'请选择显示模块',
                    trigger: 'change',
                    },
                ],
            },
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 18,
                    offset: 6,
                },
            },
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol2: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            tailFormItemLayout: {
                wrapperCol: {
                    xs: {
                        span: 24,
                        offset: 0,
                    },
                    sm: {
                        span: 18,
                        offset: 6,
                    },
                },
            },
            userTreeData:[],
            userId:"",
            displayHref:'',
            siteServiceList:[],
            mpointGrs:[],
            miconList:[],
            siteId:0,
            kind:0,
            name:'',
            equipmentDetail:'',
            mainCls:'main',
            displayTypes:[
                {no:1,text:this.$t('distribution.a32')}, //'普通大屏'
                {no:2,text:this.$t('distribution.a33')},//'独立部署服务大屏'
                {no:3,text:this.$t('distribution.a34')},//'普通小屏'
                {no:4,text:this.$t('distribution.a35')},//'平板屏幕'
                {no:5,text:this.$t('distribution.a36')},//'人脸终端屏幕'
                {no:6,text:this.$t('distribution.a37')},//'广告屏'
            ],
            themeList:[
                {no:'board' ,text:this.$t('distribution.a38')},//'主题一'
                {no:'board2',text:this.$t('distribution.a39')},//'主题二'
            // {no:3,text:'主题三'},
            ],
            labelCol3: { span: 4 },
            wrapperCol3: { span: 14 },
        };
    },
    watch:{
        "id":{
            handler(val,oval){
                console.log("this>>>>>>>>>>>>>>>",val);
                if(val*1>0 && val*1!==oval*1){
                    this.kind = 0;
                    this.siteId = this.$route.query.equipmentSiteId;
                    this.equipmentDetail = 'equipment-detail2';
                    this.mainCls = 'main';
                    this.userId="";
                    this.userTreeData=[];
                    this.displayHref="";
                    this.loadInfo(val);
                }else{
                    this.userId="";
                    this.userTreeData=[];
                    this.displayHref="";
                }
            },
            deep:true,
            immediate:true,
        },
    },
    mounted(){
        if(this.$route.query.deviceId){
            this.kind = 1;
            this.siteId = this.$route.query.equipmentSiteId;

            this.equipmentDetail = 'equipment-detail1';
            this.mainCls = 'main main-margin';
            this.loadInfo(this.$route.query.deviceId);
        }else{
            this.kind = 0;
            this.siteId = this.equipmentSiteId;
            this.equipmentDetail = 'equipment-detail2';
            this.mainCls = 'main';
        }
        console.log(this.mainCls);
    },
    
    methods:{
        // 页面后退
        goBack() {
            this.$router.go(-1);
        },
        onCopy(){
            this.$message.success( this.baseInfo.displayUrl + this.$t('distribution.a43')) ;//" 复制成功！"
        },
        themeChange(value,e){
           this.themeImage = value;
        },
        userChange(value,names,e){
            
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId:   0,
                tenantId: 0,
                userId:this.boardForm.userId,
            };
            this.loading = true;
            getUserSiteServiceList(params)
            .then((res) => {
                console.log("LoadData Result",res);
                if(res.errorCode == '00'){
                    this.boardForm.model = [];
                    this.siteServiceList = res.data;
                } 
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
            
        },
        resetForm() {
            if(this.$refs.boardForm){
                this.$refs.boardForm.resetFields();
            }
        },
        saveBoardCfg(){
            this.$refs.boardForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title:this.$t('distribution.a44'),//'确认要保存所设置的发布信息参数吗？',
                        centered: true,
                        onOk: () => {
                            this.initAccessKey();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        initAccessKey(){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId:   0,
                tenantId: 0,
                equipmentSiteId: this.siteId,
                userId:this.boardForm.userId,
                deviceId:this.baseInfo.id,
                title:this.boardForm.title,
                model:this.boardForm.model,
                flushTime:this.boardForm.flushTime,
                theme:this.boardForm.theme,
            };
            console.log("LoadData params",params);
            this.loading = true;
            initBoardAccessKey(params)
            .then((res) => {
                console.log("LoadData Result",res);
                if(res.errorCode == '00'){
                    this.$message.success(res.msg);
                    this.loadInfo(this.baseInfo.id);
                } else {
                    this.$message.error(res.msg)
                    this.loading = false;
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        configEquipment(){
            this.$refs.configForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: this.$t('distribution.a45'),//'确认要保存所设置的配置信息吗？',//
                        centered: true,
                        onOk: () => {
                            let params =  {
                                action:3,
                                clientId: this.$store.getters.clientId,
                                sitegrId: this.$store.getters.sitegrId,
                                siteId:   0,
                                equipmentSiteId: this.baseInfo.siteId,
                                tenantId: 0,
                                id:this.configForm.id,
                                monitorId: this.configForm.monitorId,
                                deviceIp :this.configForm.deviceIp,
                                devicePort: this.configForm.devicePort,
                                deviceKey:this.configForm.deviceKey,
                                serverIp: this.configForm.serverIp,
                                serverPort:this.configForm.serverPort,
                                displayUrl:this.configForm.displayUrl,
                                memo:"",
                            };
                            saveCommonEquipment(params)
                            .then((res) => {
                                console.log("SaveCommonEquipment Result",res);
                                if(res.errorCode == '03'){
                                    this.$message.success(res.msg);
                                    this.loadInfo(this.baseInfo.id);
                                } else {
                                    this.$message.error(res.msg)
                                    this.loading = false;
                                }
                            })
                            .catch((err) => {
                                this.loading = false;
                                console.log(err);
                            });
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

            
        },
        loadInfo(id){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: 0,
                equipmentSiteId:this.siteId,
                tenantId: 0,
                id:id,
            };
            console.log("InitPage Params",params);
            this.loading = true;
            getCommonEquipment(params)
            .then((res) => {
                console.log("LoadData Result",res);
                if(res.errorCode == '00'){
                    let {commonEquipment,usersTree,board,siteServiceList,iconList,pointGrs} = res.data;
                    this.mpointGrs = pointGrs;
                    this.miconList = iconList;
                    if(commonEquipment){
                        console.log("commonEquipment ",commonEquipment.id);
                        this.baseInfo.id				= commonEquipment.id				;
                        this.baseInfo.name				= commonEquipment.name			    ;
                        this.baseInfo.monitorId			= commonEquipment.monitorId		    ;
                        this.baseInfo.monitorNo			= commonEquipment.monitorNo		    ;
                        this.baseInfo.grs				= commonEquipment.grs				;
                        this.baseInfo.iconNo			= commonEquipment.iconNo+""			;
                        this.baseInfo.displayDevice		= commonEquipment.displayDevice	    ;
                        this.baseInfo.description		= commonEquipment.description		;
                        this.baseInfo.siteId			= commonEquipment.siteId			;
                        this.baseInfo.smartGatewayId	= commonEquipment.smartGatewayId	;
                        this.baseInfo.parentId			= commonEquipment.parentId		    ;
                        this.baseInfo.deviceId			= commonEquipment.deviceId		    ;
                        this.baseInfo.displayUrl		= commonEquipment.displayURL		;
                        this.baseInfo.deviceKey			= commonEquipment.displayKey	    ;
                    }

                    if(this.baseInfo.displayDevice == 1 && this.baseInfo.deviceKey){
                        let url = window.location.origin+"/#/board?key="+this.baseInfo.deviceKey;
                        let chref = '<a href="'+url+'" target="_blank">'+url+'</a>';
                        this.displayHref = chref;
                        this.baseInfo.displayUrl = url;
                    // }  else if(this.baseInfo.displayDevice === 2){
                    //     if(this.baseInfo.displayUrl){

                    //         let chref = '<a href="'+this.baseInfo.displayUrl+'" target="_blank">'+this.baseInfo.displayUrl+'</a>';
                    //         this.displayHref = chref;
                    //     }
                        // this.baseInfo.displayUrl = url;
                    }else if(this.baseInfo.displayDevice === 2 || this.baseInfo.displayDevice === 4){
                        // let url = window.location.origin+"/#/display?key="+this.baseInfo.deviceKey;
                        // let url = window.location.origin+"/"+this.baseInfo.deviceKey;
                        let url = window.location.origin+"/"+this.baseInfo.deviceKey+"/#/";
                        let chref = '<a href="'+url+'" target="_blank">'+url+'</a>';
                        this.displayHref = chref;
                        this.baseInfo.displayUrl = url;
                        // if(this.baseInfo.displayUrl){

                        //     let chref = '<a href="'+this.baseInfo.displayUrl+'" target="_blank">'+this.baseInfo.displayUrl+'</a>';
                        //     this.displayHref = chref;
                        // }
                        // this.baseInfo.displayUrl = url;
                    }
                
                    this.configForm.id			= commonEquipment.id;
                    this.configForm.monitorId	= commonEquipment.monitorId;
                    this.configForm.deviceIp 	= commonEquipment.deviceIp;
                    this.configForm.devicePort	= commonEquipment.devicePort	;
                    this.configForm.deviceKey	= commonEquipment.displayKey	;
                    this.configForm.serverIp	= commonEquipment.serverIp	    ;
                    this.configForm.serverPort	= commonEquipment.serverPort	;
                    this.configForm.displayUrl	= commonEquipment.displayURL	;
                    this.configForm.memo		= commonEquipment.memo		    ;

                    this.userTreeData = usersTree;
                    this.siteServiceList = siteServiceList;

                    if(board){
                        this.userId = board.userId;
                        this.boardForm.userId    = board.userId;
                        this.boardForm.title     = board.title;
                        this.boardForm.flushTime = board.flushTime;
                        this.boardForm.model     = board.model==null?[]:board.model;
                        this.boardForm.theme     = board.theme==null?'board':board.theme;
                        this.themeImage = this.boardForm.theme;
                    }
                } 
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
    },
    computed:{
        formItemLayout() {
            const { layout } = this.configForm;
            return layout === 'horizontal'
                ? {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                }
                : {};
            },
    }
}
</script>
<style scoped>
.equipment-detail1{
    width: calc(100% - 40px);
    height: calc(100% - 20px);
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #bdbcbc;
    background: #ffffff;
    overflow: hidden;

}
.equipment-detail2{
    width: 100%;
    height: 100%;
    overflow: auto;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #cccdcd;
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
}
.header-title {
  display: flex;
  align-items: center;
}
.header-title span {
  margin-right: 30px;
}

.header-icon{
    color:#7682CE;
    font-size:0.26rem;
    margin:0.02rem 0.15rem 0.02rem 0.02rem;
}
.main{
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
}
.main-margin{
    height: calc(100% -10px);
    margin-top: 60px;
}
.box {
  width: 100%;
  box-shadow:0 5px 10px #bdbcbc;
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  overflow: auto;
  position: relative;
}
.theme-image{
    position:absolute;
    top:20px;
    right: 20px;
    width: 40%;

}
div /deep/ .ant-modal-body{
    padding: 8px;
}
div /deep/ .ant-form-item-label{
    text-align: left;
}
</style>